import React, { useEffect } from "react"
import { Link } from "gatsby"
import SmoothImage from '../components/smooth-image'
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {

  return (
    <div class="container home-container">
      <SEO title="Un metro por un metro" />

      <div className="display-sm row mt-5">
        <div className="col-12 col-sm-6">
          <h1 className="page__subtitle" >Acto primero</h1>
          <h2 className="page__title">Un metro por un metro</h2>
          <p class="page__author">Ensayo de Fernanda Villegas</p>
          <Link class="home-link" to="/advertencia">Advertencia</Link>
          <Link to="/acto/lluvia">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-7.jpg" title="Lluvia" />
          </Link>
          <Link to="/acto/canto">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-8.jpg" title="Canto" />
          </Link>
          <Link to="/acto/sendero">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-13.jpg" title="Sendero" />
          </Link>
          <Link to="/acto/animal">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-4.jpg" title="Animal" />
          </Link>
          <Link to="/acto/noche">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-11.jpg" title="Noche" />
          </Link>

        </div>

        <div className="col-12 col-sm-6">
          <Link to="/acto/pajaro">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-6.jpg" title="Pajaro" />
          </Link>
          <Link to="/acto/viento">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-3.jpg" title="Viento" />
          </Link>
          <Link to="/acto/hoja">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-10.jpg" title="Hoja" />
          </Link>
          <Link to="/acto/atardecer">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-26.jpg" title="Atardecer" />
          </Link>
          <Link to="/va-a-venir-el-tiempo">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-9.jpg" title="Va a venir el tiempo" />
          </Link>
        </div>
      </div>

      <div className="display-md row mt-5">
        <div className="col-4">
          <h1 className="page__subtitle" >Acto primero</h1>
          <h2 className="page__title">Un metro por un metro</h2>
          <p class="page__author">Ensayo de Fernanda Villegas</p>
          <Link class="home-link" to="/advertencia">Advertencia</Link>
          <Link to="/acto/canto">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-8.jpg" title="Canto" />
          </Link>
          <Link to="/acto/atardecer">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-26.jpg" title="Atardecer" />
          </Link>


        </div>

        <div className="col-4">
          <Link to="/acto/pajaro">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-6.jpg" title="Pajaro" />
          </Link>

          <Link to="/acto/hoja">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-10.jpg" title="Hoja" />
          </Link>
          <Link to="/acto/animal">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-4.jpg" title="Animal" />
          </Link>
          <Link to="/va-a-venir-el-tiempo">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-9.jpg" title="Va a venir el tiempo" />
          </Link>
        </div>

        <div className="col-4">

          <Link to="/acto/lluvia">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-7.jpg" title="Lluvia" />
          </Link>
          <Link to="/acto/viento">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-3.jpg" title="Viento" />
          </Link>
          <Link to="/acto/sendero">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-13.jpg" title="Sendero" />
          </Link>

          <Link to="/acto/noche">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-11.jpg" title="Noche" />
          </Link>

        </div>
      </div>

      <div className="display-lg row mt-5">
        <div className="col-3">
          <h1 className="page__subtitle" >Acto primero</h1>
          <h2 className="page__title">Un metro por un metro</h2>
          <p class="page__author">Ensayo de Fernanda Villegas</p>
          <Link class="home-link" to="/advertencia">Advertencia</Link>
          <Link to="/acto/sendero">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-13.jpg" title="Sendero" />
          </Link>

          <Link to="/va-a-venir-el-tiempo">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-9.jpg" title="Va a venir el tiempo" />
          </Link>

        </div>

        <div className="col-3">
          <Link to="/acto/pajaro">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-6.jpg" title="Pajaro" />
          </Link>


          <Link to="/acto/animal">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-4.jpg" title="Animal" />
          </Link>

        </div>

        <div className="col-3">
          <Link to="/acto/lluvia">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-7.jpg" title="Lluvia" />
          </Link>
          <Link to="/acto/canto">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-8.jpg" title="Canto" />
          </Link>
          <Link to="/acto/atardecer">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-26.jpg" title="Atardecer" />
          </Link>

        </div>

        <div className="col-3">

          <Link to="/acto/hoja">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-10.jpg" title="Hoja" />
          </Link>


          <Link to="/acto/viento">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-3.jpg" title="Viento" />
          </Link>

          <Link to="/acto/noche">
            <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-11.jpg" title="Noche" />
          </Link>

        </div>
      </div>
      <footer style={{
        marginTop: `8rem`
      }}>
        <p>Un proyecto de
          {` `}
          <a href="http://teatroojo.mx/">Teatro Ojo</a></p>
      </footer>
    </div>
  )
}
export default IndexPage
